/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.kit-color li {
  display: inline-block;
  width: 20%;
  height: 100px;
  color: #000;
  margin: 4px;
  line-height: 34px;
  text-align: center; }

.kit-color .default {
  color: #fff;
  background-color: #2C2C2C; }

.kit-color .blue-dark {
  color: #fff;
  background: #20214F; }

.kit-color .violet {
  color: #fff;
  background-color: #6C4675; }

.kit-color .background {
  background-color: #FBFBFB; }

.kit-color .grey-light {
  background-color: #B7B7B7; }

.kit-color .grey {
  background-color: #4f4e4e; }

.kit-color .red {
  background-color: #f55; }

.kit-color .blue {
  color: #fff;
  background-color: #4D718B; }

.kit-color .blue-light {
  background-color: #DBE6EC; }

.kit-color .status-error {
  background-color: #E06666; }

.kit-color .status-new {
  background-color: #8E7CC3; }

.kit-color .status-success {
  background-color: #93C47D; }

.kit-color .status-warning {
  background-color: #F6B26B; }

.kit-color .status-waiting {
  background-color: #76A5AF; }

#dashboardDealer {
  @custom-media --sm-viewport only screen and (min-width: 48em);
  @custom-media --md-viewport only screen and (min-width: 64em);
  @custom-media --lg-viewport only screen and (min-width: 75em); }
  #dashboardDealer :root {
    --gutter-width: 1rem;
    --outer-margin: 2rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
    --xs-min: 30;
    --sm-min: 48;
    --md-min: 64;
    --lg-min: 75;
    --screen-xs-min: var(--xs-min)em;
    --screen-sm-min: var(--sm-min)em;
    --screen-md-min: var(--md-min)em;
    --screen-lg-min: var(--lg-min)em;
    --container-sm: calc(var(--sm-min) + var(--gutter-width));
    --container-md: calc(var(--md-min) + var(--gutter-width));
    --container-lg: calc(var(--lg-min) + var(--gutter-width)); }
  #dashboardDealer .container-fluid, #dashboardDealer .container {
    margin-right: auto;
    margin-left: auto; }
  #dashboardDealer .container-fluid {
    padding-right: var(--outer-margin, 2rem);
    padding-left: var(--outer-margin, 2rem); }
  #dashboardDealer .row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: var(--gutter-compensation, -0.5rem); }
  #dashboardDealer .row.reverse {
    flex-direction: row-reverse; }
  #dashboardDealer .col.reverse {
    flex-direction: column-reverse; }
  #dashboardDealer .col-xs,
  #dashboardDealer .col-xs-1,
  #dashboardDealer .col-xs-2,
  #dashboardDealer .col-xs-3,
  #dashboardDealer .col-xs-4,
  #dashboardDealer .col-xs-5,
  #dashboardDealer .col-xs-6,
  #dashboardDealer .col-xs-7,
  #dashboardDealer .col-xs-8,
  #dashboardDealer .col-xs-9,
  #dashboardDealer .col-xs-10,
  #dashboardDealer .col-xs-11,
  #dashboardDealer .col-xs-12,
  #dashboardDealer .col-xs-offset-0,
  #dashboardDealer .col-xs-offset-1,
  #dashboardDealer .col-xs-offset-2,
  #dashboardDealer .col-xs-offset-3,
  #dashboardDealer .col-xs-offset-4,
  #dashboardDealer .col-xs-offset-5,
  #dashboardDealer .col-xs-offset-6,
  #dashboardDealer .col-xs-offset-7,
  #dashboardDealer .col-xs-offset-8,
  #dashboardDealer .col-xs-offset-9,
  #dashboardDealer .col-xs-offset-10,
  #dashboardDealer .col-xs-offset-11,
  #dashboardDealer .col-xs-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem); }
  #dashboardDealer .col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  #dashboardDealer .col-xs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  #dashboardDealer .col-xs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  #dashboardDealer .col-xs-3 {
    flex-basis: 25%;
    max-width: 25%; }
  #dashboardDealer .col-xs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  #dashboardDealer .col-xs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  #dashboardDealer .col-xs-6 {
    flex-basis: 50%;
    max-width: 50%; }
  #dashboardDealer .col-xs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  #dashboardDealer .col-xs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  #dashboardDealer .col-xs-9 {
    flex-basis: 75%;
    max-width: 75%; }
  #dashboardDealer .col-xs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  #dashboardDealer .col-xs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  #dashboardDealer .col-xs-12 {
    flex-basis: 100%;
    max-width: 100%; }
  #dashboardDealer .col-xs-offset-0 {
    margin-left: 0; }
  #dashboardDealer .col-xs-offset-1 {
    margin-left: 8.33333333%; }
  #dashboardDealer .col-xs-offset-2 {
    margin-left: 16.66666667%; }
  #dashboardDealer .col-xs-offset-3 {
    margin-left: 25%; }
  #dashboardDealer .col-xs-offset-4 {
    margin-left: 33.33333333%; }
  #dashboardDealer .col-xs-offset-5 {
    margin-left: 41.66666667%; }
  #dashboardDealer .col-xs-offset-6 {
    margin-left: 50%; }
  #dashboardDealer .col-xs-offset-7 {
    margin-left: 58.33333333%; }
  #dashboardDealer .col-xs-offset-8 {
    margin-left: 66.66666667%; }
  #dashboardDealer .col-xs-offset-9 {
    margin-left: 75%; }
  #dashboardDealer .col-xs-offset-10 {
    margin-left: 83.33333333%; }
  #dashboardDealer .col-xs-offset-11 {
    margin-left: 91.66666667%; }
  #dashboardDealer .start-xs {
    justify-content: flex-start;
    text-align: start; }
  #dashboardDealer .center-xs {
    justify-content: center;
    text-align: center; }
  #dashboardDealer .end-xs {
    justify-content: flex-end;
    text-align: end; }
  #dashboardDealer .top-xs {
    align-items: flex-start; }
  #dashboardDealer .middle-xs {
    align-items: center; }
  #dashboardDealer .bottom-xs {
    align-items: flex-end; }
  #dashboardDealer .around-xs {
    justify-content: space-around; }
  #dashboardDealer .between-xs {
    justify-content: space-between; }
  #dashboardDealer .first-xs {
    order: -1; }
  #dashboardDealer .last-xs {
    order: 1; }
  @media (--sm-viewport) {
    #dashboardDealer .container {
      width: var(--container-sm, 46rem); }
    #dashboardDealer .col-sm,
    #dashboardDealer .col-sm-1,
    #dashboardDealer .col-sm-2,
    #dashboardDealer .col-sm-3,
    #dashboardDealer .col-sm-4,
    #dashboardDealer .col-sm-5,
    #dashboardDealer .col-sm-6,
    #dashboardDealer .col-sm-7,
    #dashboardDealer .col-sm-8,
    #dashboardDealer .col-sm-9,
    #dashboardDealer .col-sm-10,
    #dashboardDealer .col-sm-11,
    #dashboardDealer .col-sm-12,
    #dashboardDealer .col-sm-offset-0,
    #dashboardDealer .col-sm-offset-1,
    #dashboardDealer .col-sm-offset-2,
    #dashboardDealer .col-sm-offset-3,
    #dashboardDealer .col-sm-offset-4,
    #dashboardDealer .col-sm-offset-5,
    #dashboardDealer .col-sm-offset-6,
    #dashboardDealer .col-sm-offset-7,
    #dashboardDealer .col-sm-offset-8,
    #dashboardDealer .col-sm-offset-9,
    #dashboardDealer .col-sm-offset-10,
    #dashboardDealer .col-sm-offset-11,
    #dashboardDealer .col-sm-offset-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: var(--half-gutter-width, 0.5rem);
      padding-left: var(--half-gutter-width, 0.5rem); }
    #dashboardDealer .col-sm {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%; }
    #dashboardDealer .col-sm-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%; }
    #dashboardDealer .col-sm-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%; }
    #dashboardDealer .col-sm-3 {
      flex-basis: 25%;
      max-width: 25%; }
    #dashboardDealer .col-sm-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%; }
    #dashboardDealer .col-sm-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%; }
    #dashboardDealer .col-sm-6 {
      flex-basis: 50%;
      max-width: 50%; }
    #dashboardDealer .col-sm-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%; }
    #dashboardDealer .col-sm-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%; }
    #dashboardDealer .col-sm-9 {
      flex-basis: 75%;
      max-width: 75%; }
    #dashboardDealer .col-sm-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%; }
    #dashboardDealer .col-sm-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%; }
    #dashboardDealer .col-sm-12 {
      flex-basis: 100%;
      max-width: 100%; }
    #dashboardDealer .col-sm-offset-0 {
      margin-left: 0; }
    #dashboardDealer .col-sm-offset-1 {
      margin-left: 8.33333333%; }
    #dashboardDealer .col-sm-offset-2 {
      margin-left: 16.66666667%; }
    #dashboardDealer .col-sm-offset-3 {
      margin-left: 25%; }
    #dashboardDealer .col-sm-offset-4 {
      margin-left: 33.33333333%; }
    #dashboardDealer .col-sm-offset-5 {
      margin-left: 41.66666667%; }
    #dashboardDealer .col-sm-offset-6 {
      margin-left: 50%; }
    #dashboardDealer .col-sm-offset-7 {
      margin-left: 58.33333333%; }
    #dashboardDealer .col-sm-offset-8 {
      margin-left: 66.66666667%; }
    #dashboardDealer .col-sm-offset-9 {
      margin-left: 75%; }
    #dashboardDealer .col-sm-offset-10 {
      margin-left: 83.33333333%; }
    #dashboardDealer .col-sm-offset-11 {
      margin-left: 91.66666667%; }
    #dashboardDealer .start-sm {
      justify-content: flex-start;
      text-align: start; }
    #dashboardDealer .center-sm {
      justify-content: center;
      text-align: center; }
    #dashboardDealer .end-sm {
      justify-content: flex-end;
      text-align: end; }
    #dashboardDealer .top-sm {
      align-items: flex-start; }
    #dashboardDealer .middle-sm {
      align-items: center; }
    #dashboardDealer .bottom-sm {
      align-items: flex-end; }
    #dashboardDealer .around-sm {
      justify-content: space-around; }
    #dashboardDealer .between-sm {
      justify-content: space-between; }
    #dashboardDealer .first-sm {
      order: -1; }
    #dashboardDealer .last-sm {
      order: 1; } }
  @media (--md-viewport) {
    #dashboardDealer .container {
      width: var(--container-md, 61rem); }
    #dashboardDealer .col-md,
    #dashboardDealer .col-md-1,
    #dashboardDealer .col-md-2,
    #dashboardDealer .col-md-3,
    #dashboardDealer .col-md-4,
    #dashboardDealer .col-md-5,
    #dashboardDealer .col-md-6,
    #dashboardDealer .col-md-7,
    #dashboardDealer .col-md-8,
    #dashboardDealer .col-md-9,
    #dashboardDealer .col-md-10,
    #dashboardDealer .col-md-11,
    #dashboardDealer .col-md-12,
    #dashboardDealer .col-md-offset-0,
    #dashboardDealer .col-md-offset-1,
    #dashboardDealer .col-md-offset-2,
    #dashboardDealer .col-md-offset-3,
    #dashboardDealer .col-md-offset-4,
    #dashboardDealer .col-md-offset-5,
    #dashboardDealer .col-md-offset-6,
    #dashboardDealer .col-md-offset-7,
    #dashboardDealer .col-md-offset-8,
    #dashboardDealer .col-md-offset-9,
    #dashboardDealer .col-md-offset-10,
    #dashboardDealer .col-md-offset-11,
    #dashboardDealer .col-md-offset-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: var(--half-gutter-width, 0.5rem);
      padding-left: var(--half-gutter-width, 0.5rem); }
    #dashboardDealer .col-md {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%; }
    #dashboardDealer .col-md-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%; }
    #dashboardDealer .col-md-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%; }
    #dashboardDealer .col-md-3 {
      flex-basis: 25%;
      max-width: 25%; }
    #dashboardDealer .col-md-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%; }
    #dashboardDealer .col-md-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%; }
    #dashboardDealer .col-md-6 {
      flex-basis: 50%;
      max-width: 50%; }
    #dashboardDealer .col-md-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%; }
    #dashboardDealer .col-md-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%; }
    #dashboardDealer .col-md-9 {
      flex-basis: 75%;
      max-width: 75%; }
    #dashboardDealer .col-md-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%; }
    #dashboardDealer .col-md-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%; }
    #dashboardDealer .col-md-12 {
      flex-basis: 100%;
      max-width: 100%; }
    #dashboardDealer .col-md-offset-0 {
      margin-left: 0; }
    #dashboardDealer .col-md-offset-1 {
      margin-left: 8.33333333%; }
    #dashboardDealer .col-md-offset-2 {
      margin-left: 16.66666667%; }
    #dashboardDealer .col-md-offset-3 {
      margin-left: 25%; }
    #dashboardDealer .col-md-offset-4 {
      margin-left: 33.33333333%; }
    #dashboardDealer .col-md-offset-5 {
      margin-left: 41.66666667%; }
    #dashboardDealer .col-md-offset-6 {
      margin-left: 50%; }
    #dashboardDealer .col-md-offset-7 {
      margin-left: 58.33333333%; }
    #dashboardDealer .col-md-offset-8 {
      margin-left: 66.66666667%; }
    #dashboardDealer .col-md-offset-9 {
      margin-left: 75%; }
    #dashboardDealer .col-md-offset-10 {
      margin-left: 83.33333333%; }
    #dashboardDealer .col-md-offset-11 {
      margin-left: 91.66666667%; }
    #dashboardDealer .start-md {
      justify-content: flex-start;
      text-align: start; }
    #dashboardDealer .center-md {
      justify-content: center;
      text-align: center; }
    #dashboardDealer .end-md {
      justify-content: flex-end;
      text-align: end; }
    #dashboardDealer .top-md {
      align-items: flex-start; }
    #dashboardDealer .middle-md {
      align-items: center; }
    #dashboardDealer .bottom-md {
      align-items: flex-end; }
    #dashboardDealer .around-md {
      justify-content: space-around; }
    #dashboardDealer .between-md {
      justify-content: space-between; }
    #dashboardDealer .first-md {
      order: -1; }
    #dashboardDealer .last-md {
      order: 1; } }
  @media (--lg-viewport) {
    #dashboardDealer .container {
      width: var(--container-lg, 71rem); }
    #dashboardDealer .col-lg,
    #dashboardDealer .col-lg-1,
    #dashboardDealer .col-lg-2,
    #dashboardDealer .col-lg-3,
    #dashboardDealer .col-lg-4,
    #dashboardDealer .col-lg-5,
    #dashboardDealer .col-lg-6,
    #dashboardDealer .col-lg-7,
    #dashboardDealer .col-lg-8,
    #dashboardDealer .col-lg-9,
    #dashboardDealer .col-lg-10,
    #dashboardDealer .col-lg-11,
    #dashboardDealer .col-lg-12,
    #dashboardDealer .col-lg-offset-0,
    #dashboardDealer .col-lg-offset-1,
    #dashboardDealer .col-lg-offset-2,
    #dashboardDealer .col-lg-offset-3,
    #dashboardDealer .col-lg-offset-4,
    #dashboardDealer .col-lg-offset-5,
    #dashboardDealer .col-lg-offset-6,
    #dashboardDealer .col-lg-offset-7,
    #dashboardDealer .col-lg-offset-8,
    #dashboardDealer .col-lg-offset-9,
    #dashboardDealer .col-lg-offset-10,
    #dashboardDealer .col-lg-offset-11,
    #dashboardDealer .col-lg-offset-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: var(--half-gutter-width, 0.5rem);
      padding-left: var(--half-gutter-width, 0.5rem); }
    #dashboardDealer .col-lg {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%; }
    #dashboardDealer .col-lg-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%; }
    #dashboardDealer .col-lg-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%; }
    #dashboardDealer .col-lg-3 {
      flex-basis: 25%;
      max-width: 25%; }
    #dashboardDealer .col-lg-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%; }
    #dashboardDealer .col-lg-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%; }
    #dashboardDealer .col-lg-6 {
      flex-basis: 50%;
      max-width: 50%; }
    #dashboardDealer .col-lg-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%; }
    #dashboardDealer .col-lg-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%; }
    #dashboardDealer .col-lg-9 {
      flex-basis: 75%;
      max-width: 75%; }
    #dashboardDealer .col-lg-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%; }
    #dashboardDealer .col-lg-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%; }
    #dashboardDealer .col-lg-12 {
      flex-basis: 100%;
      max-width: 100%; }
    #dashboardDealer .col-lg-offset-0 {
      margin-left: 0; }
    #dashboardDealer .col-lg-offset-1 {
      margin-left: 8.33333333%; }
    #dashboardDealer .col-lg-offset-2 {
      margin-left: 16.66666667%; }
    #dashboardDealer .col-lg-offset-3 {
      margin-left: 25%; }
    #dashboardDealer .col-lg-offset-4 {
      margin-left: 33.33333333%; }
    #dashboardDealer .col-lg-offset-5 {
      margin-left: 41.66666667%; }
    #dashboardDealer .col-lg-offset-6 {
      margin-left: 50%; }
    #dashboardDealer .col-lg-offset-7 {
      margin-left: 58.33333333%; }
    #dashboardDealer .col-lg-offset-8 {
      margin-left: 66.66666667%; }
    #dashboardDealer .col-lg-offset-9 {
      margin-left: 75%; }
    #dashboardDealer .col-lg-offset-10 {
      margin-left: 83.33333333%; }
    #dashboardDealer .col-lg-offset-11 {
      margin-left: 91.66666667%; }
    #dashboardDealer .start-lg {
      justify-content: flex-start;
      text-align: start; }
    #dashboardDealer .center-lg {
      justify-content: center;
      text-align: center; }
    #dashboardDealer .end-lg {
      justify-content: flex-end;
      text-align: end; }
    #dashboardDealer .top-lg {
      align-items: flex-start; }
    #dashboardDealer .middle-lg {
      align-items: center; }
    #dashboardDealer .bottom-lg {
      align-items: flex-end; }
    #dashboardDealer .around-lg {
      justify-content: space-around; }
    #dashboardDealer .between-lg {
      justify-content: space-between; }
    #dashboardDealer .first-lg {
      order: -1; }
    #dashboardDealer .last-lg {
      order: 1; } }

.joyride-beacon {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    animation: joyride-tooltip 0.4s forwards;
    animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 20px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 16px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@keyframes joyride-tooltip {
  0% {
    transform: scale(0.1); }
  100% {
    opacity: 1;
    transform: perspective(1px) scale(1); } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1); }
  45% {
    opacity: 0.7;
    transform: scale(0.75); }
  100% {
    opacity: 0.9;
    transform: scale(1); } }

html, body {
  height: 100%; }

body {
  height: 100%;
  cursor: default; }

body.login {
  background-color: white; }

a {
  color: #2C2C2C; }

a:hover, a:focus {
  color: black; }

h1 {
  font-size: 20pt;
  font-weight: 100; }

h2 {
  font-size: 32pt;
  font-weight: 100;
  margin: 0; }

h2 .subtitle {
  margin-left: 18px; }

h2 .separator {
  margin-left: 20px; }

h3 {
  font-size: 15pt;
  font-weight: 300;
  margin: 4px 0; }

h4 {
  font-weight: 300;
  margin: 5px 0; }

input, select {
  padding: 6px;
  margin: 0 8px;
  border: 1px solid #B7B7B7; }
  input:disabled, select:disabled {
    border-color: #847f7f;
    color: #847f7f; }

input[type="text"]:disabled {
  color: #000000; }

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus {
  border: 1px solid #251A50;
  box-shadow: none; }

input.error {
  border: 1px solid red; }

textarea {
  width: 100%;
  margin-top: 10px;
  margin: 0 8px; }

button {
  border: 1px solid #EFEFEF;
  border-radius: 2px;
  background-color: transparent;
  padding: 5px 15px; }

button.active {
  background-color: #EFEFEF; }

button.apply {
  border: 1px solid #D9EAD3;
  background-color: #D9EAD3; }

button.apply:hover, button.apply.active {
  background-color: #e0f1da; }

button.large {
  border: none;
  background-color: rgba(0, 0, 0, 0.27);
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px; }

button.large:hover, button.large.active {
  background-color: #DBE6EC; }

button.confirm {
  color: #fff;
  background: #4D718B; }

button.options {
  color: #4D718B;
  border-color: #4D718B;
  width: 177px;
  height: 35px;
  text-transform: lowercase;
  font-size: 14px; }

button.options:hover {
  color: white;
  background-color: #4D718B; }

div.message {
  height: 38px;
  padding-top: 9px;
  margin-bottom: 0px; }

div.form {
  position: relative; }

.loading:not(.no-menu) {
  height: calc(100vh - 87px); }

.loading img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 65px; }

.wrap-box-inline {
  font-size: 0; }
  .wrap-box-inline .box-inline {
    display: inline-block;
    font-size: 15px; }

.selectLabel {
  color: #2C2C2C; }

.underlined {
  text-decoration: underline; }

.selectShortname {
  font-size: 9pt;
  font-style: italic;
  color: #333; }

.selectDescription {
  font-size: 9pt;
  color: #232323; }

.selectLabel a, .selectLabel a:active {
  color: blue; }

.selectLabel a:hover {
  text-decoration: underline; }

p.inputDescription {
  font-size: 9pt;
  color: #5e5e5e; }

.avatar {
  margin: 15px 0; }

/*
    LOG
*/
.logList {
  margin: 5px 0 0 0;
  padding: 0;
  list-style: none; }

.logRow {
  font-size: 9pt; }

.logIcon {
  color: #F6B26B; }

.logTimestamp {
  margin-left: 8px; }

.logType {
  font-weight: bold;
  margin: 0 8px; }

/*
    SETTINGS general for profile,password
*/
.settings-span {
  float: left;
  padding: 0 12px 0 12px;
  font-size: 70%;
  font-weight: bold;
  color: #808080; }

.settings-group {
  padding-bottom: 7.5px; }

/*
    Password
*/
.helpBlock {
  font-size: 85%;
  color: #FFA500; }

.WarningBlock {
  font-size: 85%;
  color: red;
  padding-bottom: 2px; }

.inputIcon {
  float: right;
  margin-right: 35px;
  margin-top: -26px;
  position: relative;
  color: green;
  z-index: 91; }

.fa-info {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
  border: 2px solid #20214F;
  border-radius: 20px;
  padding: 2px 6px;
  font-size: 13px;
  cursor: pointer; }

.pointer {
  cursor: pointer; }

.font-monospace {
  font-family: monospace; }

.wrap-valign {
  height: 100vh; }
  .wrap-valign > div {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.link-box {
  margin-top: 24px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  background: #e1e1e1; }
  .link-box i {
    font-size: 19px;
    padding: 8px; }
  .link-box.active {
    color: #2C2C2C;
    background: #DBE6EC; }
    .link-box.active .text {
      cursor: pointer; }

.top-buffer {
  margin-top: 20px; }

.navbar-default {
  font-weight: 100;
  border: 0;
  margin: 0;
  background-color: #20214F;
  border-radius: 0; }
  .navbar-default a {
    text-decoration: none; }

.navbar-default .navbar-nav .icon-menu img {
  margin-right: 5px;
  width: 20px; }

.navbar-default .navbar-nav > li > div {
  border-top: 7px solid #20214F;
  border-bottom: 6px solid #20214F;
  margin: 0 55px 0 0;
  padding: 12px 12px 10px 12px;
  color: #fff; }

.navbar-default .navbar-nav > li > div > div > a {
  color: white; }

.navbar-default .navbar-nav > li > div > div {
  margin: 0;
  padding: 0; }

.navbar-default .navbar-nav > .active > div,
.navbar-default .navbar-nav > .active > div:hover,
.navbar-default .navbar-nav > .active > div:focus {
  border-top: 5px solid #DBE6EC;
  border-bottom: 8px solid #DBE6EC;
  background-color: #DBE6EC;
  color: #2C2C2C; }

.navbar-default .navbar-nav > .active > div > div > a,
.navbar-default .navbar-nav > .active svg {
  font-weight: 400;
  color: #2C2C2C;
  fill: #2C2C2C; }

.navbar-default .navbar-collapse {
  padding: 0; }

.navbar-default .navbar-form {
  background-color: #20214F; }

.navbar-default .navbar-link {
  color: red; }

.navbar-default .fa {
  vertical-align: middle;
  margin-bottom: 1px;
  margin-right: 4px; }

.navbar-myslice a {
  cursor: pointer; }

.navbar-myslice .navbar-nav > li {
  cursor: default;
  position: relative; }
  .navbar-myslice .navbar-nav > li:hover .sub-nav {
    display: block; }
  .navbar-myslice .navbar-nav > li.sup-nav > div:hover {
    border-color: #20214F; }

.navbar-myslice .sub-nav {
  position: absolute;
  padding: 0;
  top: 50px;
  left: 0;
  display: none;
  z-index: 1;
  background: #20214F; }
  .navbar-myslice .sub-nav li {
    padding: 10px;
    color: #fff;
    list-style-type: none; }
    .navbar-myslice .sub-nav li:hover {
      background: #fff; }
      .navbar-myslice .sub-nav li:hover a {
        color: #20214F; }
    .navbar-myslice .sub-nav li a {
      color: #fff; }
    .navbar-myslice .sub-nav li.active {
      background: #DBE6EC; }
      .navbar-myslice .sub-nav li.active a {
        color: #20214F; }

.clicked {
  color: red; }

.o-slider {
  margin: 0 auto;
  padding: 30px 20px 30px 0;
  width: 40vw;
  color: #333;
  background: #f3f3f3; }
  .o-slider .image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .o-slider .image-gallery.fullscreen-modal {
      background: #000;
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 5; }
      .o-slider .image-gallery.fullscreen-modal .image-gallery-content {
        top: 50%;
        transform: translateY(-50%); }
  .o-slider .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0; }
    .o-slider .image-gallery-content.fullscreen {
      background: #000; }
      .o-slider .image-gallery-content.fullscreen .image-gallery-image {
        max-width: 100%;
        margin: 0; }
      .o-slider .image-gallery-content.fullscreen .image-gallery-fullscreen-button:after {
        color: #DBE6EC; }
      .o-slider .image-gallery-content.fullscreen .image-gallery-slide {
        background: #000; }
  .o-slider .image-gallery-slide-wrapper {
    position: relative; }
    .o-slider .image-gallery-slide-wrapper.left, .o-slider .image-gallery-slide-wrapper.right {
      display: inline-block;
      width: calc(100% - 113px); }
      @media (max-width: 768px) {
        .o-slider .image-gallery-slide-wrapper.left, .o-slider .image-gallery-slide-wrapper.right {
          width: calc(100% - 84px); } }
  .o-slider .image-gallery-fullscreen-button,
  .o-slider .image-gallery-play-button,
  .o-slider .image-gallery-left-nav,
  .o-slider .image-gallery-right-nav {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4; }
  .o-slider .image-gallery-fullscreen-button,
  .o-slider .image-gallery-play-button {
    bottom: -25px; }
  .o-slider .image-gallery-fullscreen-button {
    right: 0; }
    .o-slider .image-gallery-fullscreen-button:after {
      content: '\F0B2';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      color: #20214F;
      font-family: "Font Awesome 5 Free"; }
  .o-slider .image-gallery-play-button {
    left: 0; }
  .o-slider .image-gallery-left-nav,
  .o-slider .image-gallery-right-nav {
    color: #fff;
    font-size: 5em;
    padding: 50px 15px;
    top: 50%;
    transform: translateY(-50%); }
    .o-slider .image-gallery-left-nav[disabled],
    .o-slider .image-gallery-right-nav[disabled] {
      cursor: disabled;
      opacity: .6;
      pointer-events: none; }
    @media (max-width: 768px) {
      .o-slider .image-gallery-left-nav,
      .o-slider .image-gallery-right-nav {
        font-size: 3.4em; } }
    @media (max-width: 480px) {
      .o-slider .image-gallery-left-nav,
      .o-slider .image-gallery-right-nav {
        font-size: 2.4em; } }
  .o-slider .image-gallery-left-nav {
    left: 0; }
  .o-slider .image-gallery-right-nav {
    right: 0; }
  .o-slider .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap; }
  .o-slider .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .o-slider .image-gallery-slide.center {
      position: relative; }
    .o-slider .image-gallery-slide .image-gallery-image {
      max-width: 350px;
      margin: 0 auto; }
    .o-slider .image-gallery-slide img {
      width: 100%; }
    .o-slider .image-gallery-slide .image-gallery-description {
      background: rgba(0, 0, 0, 0.4);
      bottom: 70px;
      color: #fff;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-slide .image-gallery-description {
          bottom: 45px;
          font-size: .8em;
          padding: 8px 15px; } }
  .o-slider .image-gallery-bullets {
    bottom: -20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4; }
    .o-slider .image-gallery-bullets .image-gallery-bullets-container {
      margin: 0;
      padding: 0;
      text-align: center; }
    .o-slider .image-gallery-bullets .image-gallery-bullet {
      appearance: none;
      background-color: #DBE6EC;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      border: none;
      margin: 0 5px;
      outline: none;
      padding: 5px; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-bullets .image-gallery-bullet {
          margin: 0 3px;
          padding: 3px; } }
      @media (max-width: 480px) {
        .o-slider .image-gallery-bullets .image-gallery-bullet {
          padding: 2.7px; } }
      .o-slider .image-gallery-bullets .image-gallery-bullet.active {
        background: #20214F; }
  .o-slider .image-gallery-thumbnails-wrapper {
    position: relative; }
    .o-slider .image-gallery-thumbnails-wrapper.left, .o-slider .image-gallery-thumbnails-wrapper.right {
      display: inline-block;
      vertical-align: top;
      width: 108px; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-thumbnails-wrapper.left, .o-slider .image-gallery-thumbnails-wrapper.right {
          width: 81px; } }
      .o-slider .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .o-slider .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
        height: 100%;
        width: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0; }
        .o-slider .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .o-slider .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
          display: block;
          margin-right: 0;
          padding: 0; }
    .o-slider .image-gallery-thumbnails-wrapper.left {
      margin-right: 5px; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-thumbnails-wrapper.left {
          margin-right: 3px; } }
    .o-slider .image-gallery-thumbnails-wrapper.right {
      margin-left: 5px; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-thumbnails-wrapper.right {
          margin-left: 3px; } }
  .o-slider .image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0; }
    @media (max-width: 768px) {
      .o-slider .image-gallery-thumbnails {
        padding: 3px 0; } }
    .o-slider .image-gallery-thumbnails .image-gallery-thumbnails-container {
      cursor: pointer;
      text-align: center;
      transition: transform .45s ease-out;
      white-space: nowrap; }
  .o-slider .image-gallery-thumbnail {
    display: inline-block;
    margin-right: 5px;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px; }
    @media (max-width: 768px) {
      .o-slider .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 75px; } }
    .o-slider .image-gallery-thumbnail img {
      vertical-align: middle;
      width: 100%; }
    .o-slider .image-gallery-thumbnail.active {
      border: 4px solid #337ab7; }
      @media (max-width: 768px) {
        .o-slider .image-gallery-thumbnail.active {
          border: 3px solid #337ab7; } }
  .o-slider .image-gallery-thumbnail-label {
    color: #1a1a1a;
    font-size: 1em; }
    @media (max-width: 768px) {
      .o-slider .image-gallery-thumbnail-label {
        font-size: .8em; } }
  .o-slider .image-gallery-index {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4; }
    @media (max-width: 768px) {
      .o-slider .image-gallery-index {
        font-size: .8em;
        padding: 5px 10px; } }

.login-view .logo-image {
  padding-right: 30px; }

.login-view .logo-title {
  border-left: 1pt solid #2C2C2C;
  padding-left: 30px; }

.login-view .login-form {
  padding: 50px 0; }

.login-view .login-email {
  padding-bottom: 25px; }

.login-view .login-password {
  padding-bottom: 25px; }

.login .logo-image img {
  max-width: 300px;
  padding: 20px 0; }

.notificationContainer {
  position: fixed;
  top: -38px;
  left: 33%;
  right: 33%;
  z-index: 100;
  height: 0px;
  line-height: 35px;
  -webkit-animation: slideDown 0.5s forwards, slideUp 0.5s forwards;
  -webkit-animation-delay: 0.5s, 10s;
  animation: slideDown 0.5s forwards, slideUp 0.5s forwards;
  animation-delay: 0.5s, 10s; }

@-webkit-keyframes slideDown {
  0% {
    top: -38px; }
  100% {
    top: 0; } }

@keyframes slideDown {
  0% {
    top: -38px; }
  100% {
    top: 0; } }

@-webkit-keyframes slideUp {
  0% {
    top: 0; }
  100% {
    top: -38px; } }

@keyframes slideUp {
  0% {
    top: 0; }
  100% {
    top: -38px; } }

.notificationContainer .notificationInfo {
  background-color: #CFE2F3;
  border-bottom: 1px solid #3D85C6;
  border-right: 1px solid #3D85C6;
  border-left: 1px solid #3D85C6;
  border-radius: 0 0 5px 5px;
  padding: 0 6px; }

.notificationContainer .notificationInfo i {
  color: #3D85C6; }

.notificationContainer .notificationSuccess {
  background-color: #d9ead3;
  border-bottom: 1px solid #6AA84F;
  border-right: 1px solid #6AA84F;
  border-left: 1px solid #6AA84F;
  border-radius: 0 0 5px 5px;
  padding: 0 6px; }

.notificationContainer .notificationSuccess i {
  color: #6AA84F; }

.notificationContainer .notificationError {
  background-color: #F4CCCC;
  border-bottom: 1px solid #CC0000;
  border-right: 1px solid #CC0000;
  border-left: 1px solid #CC0000;
  border-radius: 0 0 5px 5px;
  padding: 0 6px; }

.notificationContainer .notificationError i {
  color: #CC0000; }

.notificationContainer .notificationWarning {
  background-color: #FCE5CD;
  border-bottom: 1px solid #E69138;
  border-right: 1px solid #E69138;
  border-left: 1px solid #E69138;
  border-radius: 0 0 5px 5px;
  padding: 0 6px; }

.notificationContainer .notificationWarning i {
  color: #E69138; }

.alert-info {
  color: #f55;
  border: 1px solid #f55;
  background: #FBFBFB; }

.loader-bubble {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

#bubbleAside {
  position: fixed;
  top: 9%;
  right: 3%; }
  #bubbleAside a {
    padding: 6px 0 0 0px;
    display: block;
    color: #4f4e4e;
    text-decoration: underline; }
  #bubbleAside .message {
    color: #2C2C2C; }
  #bubbleAside li {
    position: relative;
    height: 60px;
    padding: 14px 33px 14px 22px;
    border-radius: 5px; }
    #bubbleAside li .close {
      position: absolute;
      top: 5px;
      right: 5px; }
    #bubbleAside li.success, #bubbleAside li.confirm {
      background: linear-gradient(to top, #FBFBFB, rgba(147, 196, 125, 0.6) 80%); }
    #bubbleAside li.waiting, #bubbleAside li.new {
      background: linear-gradient(to top, #FBFBFB, rgba(77, 113, 139, 0.6) 80%); }
    #bubbleAside li.error {
      background: linear-gradient(to top, #FBFBFB, rgba(255, 85, 85, 0.6) 80%); }
    #bubbleAside li.warning, #bubbleAside li.pending {
      background: linear-gradient(to top, #FBFBFB, rgba(246, 178, 107, 0.6) 80%); }

.view-panel {
  padding: 25px 0; }

.p-body {
  top: 220px;
  bottom: 10px;
  padding: 0 15px;
  width: 50%;
  z-index: 90;
  background-color: #FBFBFB; }

.p-left .p-header {
  left: 0; }

.p-left .p-body {
  left: 0; }

.p-right .p-header {
  right: 0; }

.p-right .p-body {
  right: 0;
  background-color: #FBFBFB; }

.p-center .p-header {
  text-align: center; }

.p-center .p-body {
  text-align: center; }

.p-header h2 {
  padding: 0;
  margin: 0; }

.p-header h2 span {
  color: gray;
  font-size: 10pt; }

.p-header-right {
  text-align: right; }

.p-header-right button {
  margin: 18px 0 0 0; }

/*
    Panel Menu
*/
.p-menu ul {
  margin: 245px 0 0 50%;
  padding: 0;
  list-style: none;
  border-right: 1px solid #DCDCF8; }

.p-menu li {
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 15px; }

.p-menu li a:hover {
  cursor: pointer;
  border-bottom: 2pt solid #150947; }

.p-menu li i {
  text-align: center;
  width: 32px;
  margin-right: 8px; }

.profileBio textarea {
  font-size: 11pt;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #333;
  background-color: white;
  outline: none;
  box-shadow: none;
  margin-top: 0px;
  width: 100%;
  resize: none; }

.profileBio textarea:focus {
  border: 1px solid #251A50;
  box-shadow: none; }

#settingsView .elementBox .wrap-box-details > ul > li {
  display: inline-block;
  padding: 0 30px 0 0;
  margin-top: 30px; }
  #settingsView .elementBox .wrap-box-details > ul > li a, #settingsView .elementBox .wrap-box-details > ul > li i {
    border-bottom: none;
    color: #B7B7B7; }
  #settingsView .elementBox .wrap-box-details > ul > li.active a, #settingsView .elementBox .wrap-box-details > ul > li.active i {
    color: #4f4e4e; }

@media (max-width: 992px) {
  header {
    position: static; }
  .p-header {
    position: static;
    width: 100%;
    padding: 0; }
  .p-body {
    position: static;
    width: 100%;
    padding: 0; } }

@media (min-width: 0px) {
  header {
    background-color: #20214F; }
    header .logo-image {
      max-width: 200px;
      margin-top: 9px; }
    header .menu {
      font-size: 10pt;
      font-weight: 100;
      text-align: right;
      margin: 0;
      padding: 35px 0; }
  .view-header {
    position: relative;
    padding: 10px 0;
    background-color: #DBE6EC;
    color: #2C2C2C; }
    .view-header h2 {
      font-size: 13px;
      color: #4f4e4e;
      text-transform: uppercase; }
    .view-header .btn-top {
      position: absolute;
      right: 0; }
      .view-header .btn-top i {
        font-size: 14px; }
    .view-header button {
      border: none;
      padding: 0; }
  .s-header {
    margin-bottom: 15px;
    margin-top: 25px;
    border-bottom: 1px solid #DCDCF8; }
  .s-header h3 {
    font-size: 20pt;
    font-weight: 100; }
  .s-header-right {
    line-height: 26pt; }
  .s-options {
    float: right;
    text-align: right;
    margin: 0;
    padding: 0;
    list-style: none; }
  .s-options li {
    float: left;
    margin: 0;
    padding: 0 15px 0 0;
    cursor: pointer; }
  .s-options li:last-child {
    padding-right: 0; }
  .s-options li:hover {
    color: darkgreen; }
  .onelab body {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #4f4e4e;
    background-color: #FBFBFB; }
  .onelab p {
    line-height: 18px;
    padding: 8px 4px; }
  .onelab .o-title-l {
    padding: 10px 20px 10px 12px;
    margin: 10px 0;
    font-size: 20px;
    color: #4f4e4e;
    background: #DBE6EC;
    font-weight: normal; }
    .onelab .o-title-l.dark {
      color: #fff;
      background: #20214F; }
  .onelab .o-title-m {
    margin: 10px 0;
    padding: 6px 20px 6px 2px;
    font-size: 18px;
    border-bottom: 1px solid #4f4e4e;
    font-weight: normal; }
  .onelab .o-title-s {
    padding: 8px 18px 8px 0;
    font-size: 16px;
    font-weight: normal; }
    .onelab .o-title-s.icon i {
      padding-right: 6px; }
  .onelab .o-block-p {
    margin-bottom: 24px; }
  .onelab .o-block-list {
    display: inline-block;
    margin: 0 0px 20px 10px;
    padding: 0px 20px 12px 8px; }
    .onelab .o-block-list li {
      padding: 4px 0; }
    .onelab .o-block-list ul {
      padding-left: 12px; }
    .onelab .o-block-list .o-link {
      margin: 10px 0; }
  .onelab .o-block-img img {
    max-width: 100%; }
  .onelab .o-link {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: #20214F;
    border-bottom: 1px solid #20214F;
    line-height: 18px;
    font-size: 14px;
    font-weight: bolder; }
    .onelab .o-link:hover {
      text-decoration: none;
      color: #B7B7B7;
      border-color: #B7B7B7; }
    .onelab .o-link.tiny {
      border-bottom: 1px solid #20214F;
      font-weight: normal; }
      .onelab .o-link.tiny:hover {
        border-color: #B7B7B7; }
  .onelab .o-hidden-block input[type="checkbox"] {
    position: absolute;
    clip: rect(0 0 0 0);
    outline: none;
    visibility: hidden; }
    .onelab .o-hidden-block input[type="checkbox"] + label {
      position: relative;
      cursor: pointer; }
      .onelab .o-hidden-block input[type="checkbox"] + label:after {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        content: '\F077';
        font-weight: 900;
        font-size: 14px;
        right: 0;
        top: 9px; }
    .onelab .o-hidden-block input[type="checkbox"] + label + div {
      transition: max-height 0.1s ease-out;
      max-height: 0;
      overflow: hidden; }
    .onelab .o-hidden-block input[type="checkbox"]:checked + label:after {
      content: '\F078'; }
    .onelab .o-hidden-block input[type="checkbox"]:checked + label + div {
      transition: color .2s linear;
      transition: max-height 0.1s ease-in;
      max-height: 100%; }
  .o-input {
    margin: 5px 0;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    cursor: default;
    display: table;
    height: 36px;
    outline: 0; }
  .o-btn {
    width: 100%;
    border: 1px solid #20214F;
    border-radius: 0;
    color: #20214F; }
  .o-block-table table {
    margin: 12px; }
  .o-block-table th {
    padding: 12px 8px; }
  .o-block-table tr {
    border-bottom: 1px solid #DBE6EC; }
    .o-block-table tr:last-child {
      border-bottom: none; }
  .o-block-table td {
    padding: 8px 0; }
    .o-block-table td:first-child {
      padding-right: 8px;
      border-right: 1px solid #B7B7B7; }
    .o-block-table td:first-child + td {
      padding-left: 8px; }
  .o-block-table.oldfashion-style table {
    width: 100%; }
  .o-block-table.oldfashion-style td {
    padding: 14px;
    border-left: 1px solid #B7B7B7;
    border-right: 1px solid #B7B7B7; }
  .o-block-table.oldfashion-style thead tr {
    text-transform: uppercase;
    border-bottom: 1px solid #B7B7B7; }
  .o-block-table.oldfashion-style tbody tr:nth-child(even) {
    background: #efefef; }
  .o-block-table.oldfashion-style tbody tr:nth-child(odd) {
    background: #FBFBFB; }
  .max-wrap {
    max-width: 1600px;
    margin: 0 auto; }
  .context-help {
    padding: 20px;
    background-color: #DBE6EC; }
  .context-help-decorated {
    color: #6C4675; }
  .context-help-ol {
    list-style-type: decimal;
    margin: 20px; } }

@media (min-width: 1024px) {
  header .logo-image {
    margin-top: 4px; } }

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 999;
  background-color: rgba(50, 50, 50, 0.95); }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
  text-align: center; }

.dialogClose {
  position: fixed;
  top: 25px;
  right: 25px;
  color: white;
  cursor: pointer;
  font-size: 13pt; }

.dialog .d-panel {
  position: fixed;
  top: 50%;
  bottom: 0;
  width: 70vw;
  height: 90vh;
  padding: 30px;
  background-color: #FBFBFB;
  margin-top: -45vh;
  left: 50%;
  margin-left: -35vw;
  overflow-y: scroll; }
  .dialog .d-panel.small {
    width: 40vw;
    height: 58vh;
    margin-top: -26vh;
    margin-left: -20vw; }

.dialog .d-panel .d-header {
  padding-bottom: 25px; }
  .dialog .d-panel .d-header h2 {
    font-size: 22px; }

.dialog .d-panel .d-body .elementList {
  max-height: 42vh;
  overflow: scroll; }

.dialog .d-panel .d-footer {
  text-align: center;
  padding: 25px  0; }

.dialog .d-panel .d-footer button {
  margin: 0 25px; }

.dialog .d-selected {
  padding: 5px 0;
  text-align: center; }

.dialog .d-selected span {
  font-weight: 700; }

.dialog .d-selected a {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer; }

.experimentForm button {
  margin: 20px 0; }

.slices-menu {
  position: absolute;
  top: 75px;
  left: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
  color: black;
  border: 1pt solid white;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 88; }

.slices-menu::before {
  content: '';
  position: absolute;
  left: 40px;
  top: -16px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  clear: both;
  z-index: 89; }

.slices-menu > div {
  max-height: 400px;
  overflow-y: auto;
  width: 300%;
  background-color: white; }

.slices-menu ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none; }

.slices-menu li {
  float: left;
  width: 100%;
  padding: 15px;
  cursor: pointer; }

.slices-menu li:hover {
  background-color: #FBFBFB; }

.slices-menu li.active {
  background-color: #FBFBFB; }

.slices-menu li h4 {
  font-size: 13pt;
  font-weight: 400; }

.slices-menu li span {
  font-size: 9pt;
  font-weight: 100;
  font-style: italic;
  color: gray; }

.slices-menu li h5 {
  font-size: 11pt;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.slices-menu li h5:hover {
  color: #6a52d0; }

.elementListSimple {
  list-style: none;
  margin: 0;
  padding: 0 0 25px 20px; }

.elementListSimple .elementBox {
  padding-bottom: 8px;
  padding-left: 25px;
  margin-bottom: 0;
  border-bottom: 1px solid #DCDCF8;
  background-color: #DBE6EC; }

.elementBox.pointer {
  cursor: pointer; }

.elementBox.selected .element-content {
  border-left-color: #f55; }

.elementBox .arrow-right, .elementBox .check-right {
  position: absolute;
  bottom: 15px;
  right: 15px; }

.elementIcon i.fa-inverse {
  color: #20214F; }

.elementListSimple .elementIcon {
  top: 5px; }

.elementListSimple .elementIcon img {
  margin-top: -12px; }

.elementIcon.authority,
.elementIcon.settings,
.elementIcon.user,
.elementIcon.project {
  color: #20214F; }

.elementIcon.slice {
  color: #EAD1DC; }

.elementIcon.resource {
  color: #D9EAD3; }

.elementIcon.testbed {
  color: #D9EAD3; }

.elementIcon.registry {
  color: #D9EAD3; }

.elementIcon.close {
  left: auto;
  right: 10px; }

.elementTitle {
  font-size: 14pt;
  color: #20214F; }
  .elementTitle span {
    font-size: 8pt;
    color: gray; }
  .elementTitle .subtitle {
    color: #4D718B; }
    .elementTitle .subtitle i {
      padding-right: 10px; }

.elementId {
  font-style: italic;
  font-size: 8pt;
  color: gray; }

.elementLabel {
  font-size: 9pt;
  color: grey;
  margin-right: 2px; }

.elementLabel:hover a {
  color: #6a52d0; }

.wrap-summary {
  margin-top: 30px; }

.elementDetails {
  margin-top: 15px; }

.elementDetailsText {
  font-size: 9pt;
  font-style: italic;
  color: gray; }

.elementDetailsLink:hover .more, .elementDetailsLink:hover .less {
  text-decoration: underline; }

.elementDetailsLink .more, .elementDetailsLink .less {
  font-size: 10pt;
  margin: 0 8px 0 5px; }

.elementDate {
  font-size: 9pt; }

.elementButton {
  top: 85px;
  position: absolute;
  right: 25px; }

.labelBox {
  padding-bottom: 25px; }

ul.labelOptions {
  margin: 0;
  padding: 0;
  list-style: none; }

ul.labelOptions li {
  display: inline;
  font-size: 9pt;
  border-right: 1px solid #DCDCF8;
  padding-right: 15px;
  padding-left: 10px;
  cursor: pointer; }

ul.labelOptions li:last-child {
  border: 0;
  padding-right: 0; }

ul.labelOptions li:first-child {
  padding-left: 0; }

ul.labelOptions li:hover {
  text-decoration: underline; }

ul.labelOptions li i {
  margin-right: 5px; }

ul.labelOptions li.delete, ul.labelOptions li.remove {
  color: red; }

ul.labelOptions li:hover.add {
  color: #20214F; }

.elementStatus {
  font-size: 9pt;
  float: left;
  list-style: none; }

.elementStatus .new {
  color: #8E7CC3; }

.elementStatus .success, .elementStatus .enabled, .elementStatus .online,
.labelStatus .success, .labelStatus .enabled, .labelStatus .online {
  color: #93C47D; }

.elementStatus .error, .elementStatus .denied, .elementStatus .offline,
.labelStatus .error, .labelStatus .denied, .labelStatus .offline {
  color: #E06666; }

.elementStatus .warning,
.labelStatus .warning {
  color: #F6B26B; }

.elementStatus .waiting, .elementStatus .pending,
.labelStatus .waiting, .labelStatus .pending {
  color: #76A5AF; }

.technologyBox {
  background-color: white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-top: 1px solid #DEDEDE;
  padding: 25px; }

.technologyBox:first-child {
  border-radius: 4px 4px 0 0;
  border-top-color: white; }

.technologyBox:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid white; }

.technologyBox .technologyLabel {
  text-align: right; }

.technologyTestbeds {
  border-left: 2pt solid #D9EAD3;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-left: 50px; }

.technologyLabel {
  line-height: 1em;
  font-size: 20pt;
  font-weight: 100;
  padding-top: 25px;
  padding-right: 50px; }

.technologyLabel img {
  margin-bottom: 15px; }

.technologyIcon {
  vertical-align: middle;
  width: 45px;
  height: 45px; }

.tiny-list {
  padding: 0; }
  .tiny-list > li {
    border-bottom: 1px solid #B7B7B7;
    list-style-type: none;
    margin-top: 10px; }
    .tiny-list > li:first-child {
      margin-top: 0; }
    .tiny-list > li:last-child {
      padding-bottom: 30px;
      border: none; }
    .tiny-list > li > p {
      color: #4f4e4e;
      font-size: 14px;
      margin-bottom: 5px; }
    .tiny-list > li > p + p {
      margin-bottom: 10px;
      color: #B7B7B7;
      font-size: 12px; }

.elementOption i {
  margin-right: 4px; }

.elementOption.delete, .elementOption.remove {
  color: red; }

.elementOption:hover.add {
  color: #20214F; }

.elementOption:hover.create {
  color: #20214F; }

.elementList {
  list-style: none;
  margin: 0;
  padding: 5px 1px 2px 19px; }
  .elementList .elementDetailsLink {
    cursor: pointer; }
  .elementList .elementOption {
    border: 1px solid #4f4e4e;
    padding: 5px;
    border-radius: 2px;
    color: #4f4e4e;
    list-style: none;
    cursor: pointer; }
    .elementList .elementOption:hover {
      background: #4f4e4e;
      color: #DBE6EC;
      text-decoration: none; }
    .elementList .elementOption.delete {
      border-color: #E06666;
      color: #E06666; }
    .elementList .elementOption.delete:hover {
      background: #E06666;
      color: #DBE6EC; }
    .elementList .elementOption.action {
      color: #4D718B;
      border-color: #4D718B; }
    .elementList .elementOption.action:hover {
      background-color: #4D718B;
      color: #DBE6EC; }
  .elementList .svg-img {
    display: inline-block;
    width: 32px;
    top: 30px;
    position: absolute;
    left: 6px; }
    .elementList .svg-img img {
      width: 100%;
      height: auto; }

.elementBox {
  background-image: linear-gradient(to top left, #FBFBFB, #DBE6EC 90%);
  margin: 25px 0;
  position: relative; }

.element-title {
  margin: 30px 20px 40px 20px; }
  .element-title .elementIcon {
    position: absolute;
    top: 24px;
    left: 40px;
    font-size: 26px; }
  .element-title .elementTitle {
    margin-left: 30px;
    font-size: 20px; }

.element-content {
  border-right: 1px solid #B7B7B7;
  border-left: 5px solid #20214F;
  padding-left: 40px;
  padding-bottom: 15px;
  min-height: 110px; }

.history .element-content {
  border-left-color: #4f4e4e; }

.element-btn {
  position: absolute;
  right: 0;
  height: 100%; }
  .element-btn .elementOptions {
    margin-top: 15px; }
  .element-btn ul {
    padding: 0; }
  .element-btn .elementOption {
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 13px; }

.element-history {
  background-color: #d8d8d8;
  opacity: 0.7; }

.resource-reserved {
  background-color: #B7B7B7 !important; }

.summaryList {
  position: relative; }
  .summaryList .summaryIcon {
    position: absolute;
    top: -16px;
    left: 0;
    color: #20214F; }
  .summaryList > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .summaryList > ul > li {
      position: relative;
      margin: 10px 0 0 0;
      display: block; }
      .summaryList > ul > li a {
        color: #4f4e4e;
        font-size: 14px; }
    .summaryList > ul .fa {
      font-size: 18px;
      margin: 0 0 0 6px; }
  .summaryList.authority > ul {
    border-top: 2pt solid #D0E0E3; }
  .summaryList.user > ul {
    border-top: 2pt solid #CFE2F3; }
  .summaryList.project > ul {
    border-top: 2pt solid #D9D2E9; }
  .summaryList.slice > ul {
    border-top: 2pt solid #EAD1DC; }
  .summaryList.resource > ul {
    border-top: 2pt solid #D9EAD3; }

.wrap-box-details {
  padding: 0px 0 20px 0;
  border-top: 1px solid #B7B7B7; }
  .wrap-box-details .title {
    color: #B7B7B7;
    font-size: 14px;
    margin-bottom: 18px; }

#reservationView .wrap-resources {
  margin-top: 10px;
  max-height: calc(100vh - 350px);
  overflow: scroll; }

#reservationView .wrap-img {
  text-align: center; }
  #reservationView .wrap-img img {
    max-width: 500px; }
  #reservationView .wrap-img.responsive img {
    max-width: 100%; }

#reservationView .wrapper .filter {
  padding: 4px 0 10px 0;
  font-size: 14px; }
  #reservationView .wrapper .filter label {
    margin: 0 8px;
    font-weight: normal;
    color: #20214F; }
  #reservationView .wrapper .filter .wrap-input {
    margin-bottom: 10px; }
  #reservationView .wrapper .filter p {
    margin: 0; }
  #reservationView .wrapper .filter .wrap-box-inline {
    background-color: #DBE6EC; }

#reservationView .wrapper .title {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: #20214F; }

#reservationView .wrapper.right-panel {
  position: relative; }
  #reservationView .wrapper.right-panel .wrap-scroll .elementBox {
    max-height: calc(100vh - 174px);
    overflow-y: scroll;
    overflow-x: hidden; }
  #reservationView .wrapper.right-panel .wrap-scroll.documentation {
    max-height: calc(100vh - 83px);
    overflow-x: hidden;
    overflow-y: scroll; }
  #reservationView .wrapper.right-panel .wrap-testbed-map {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 84px); }
    #reservationView .wrapper.right-panel .wrap-testbed-map .wrap-svg {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
      #reservationView .wrapper.right-panel .wrap-testbed-map .wrap-svg svg {
        max-height: 500px; }
  #reservationView .wrapper.right-panel .title-right-panel {
    border-bottom: 2px solid #DBE6EC;
    color: #DBE6EC;
    padding: 0 0 8px 0;
    font-weight: 400;
    margin: 16px 0 10px 0;
    font-size: 22px; }
  #reservationView .wrapper.right-panel .testbed-list .title {
    font-size: 18px;
    margin: 14px 0; }
  #reservationView .wrapper.right-panel .testbed-list p {
    margin: 0; }
  #reservationView .wrapper.right-panel .testbed-list ul {
    padding: 0; }
  #reservationView .wrapper.right-panel .testbed-list li {
    list-style-type: none;
    line-height: 18px; }
  #reservationView .wrapper.right-panel .testbed-list > li {
    border-bottom: 1px solid #4f4e4e;
    padding: 8px 0 18px 0; }
  #reservationView .wrapper.right-panel .testbed-list .wrap-link {
    margin-top: 8px; }
    #reservationView .wrapper.right-panel .testbed-list .wrap-link a {
      border-bottom: 1px solid #2C2C2C; }
      #reservationView .wrapper.right-panel .testbed-list .wrap-link a:hover, #reservationView .wrapper.right-panel .testbed-list .wrap-link a:focus {
        text-decoration: none;
        color: #4f4e4e;
        border-color: #4f4e4e; }

#reservationView .wrapper #vm-big-button {
  position: relative;
  width: 100%;
  padding: 12px 20px;
  text-align: left;
  border: 1px solid #20214F;
  border-left-width: 4px; }
  #reservationView .wrapper #vm-big-button i {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 25px;
    color: #20214F; }

#reservationView .wrapper .wrap-playground .no-resources {
  position: absolute;
  top: 25vh;
  text-align: center;
  width: 100%;
  color: #B7B7B7; }

#reservationView .elementBox.testbed-list {
  background: #FBFBFB; }

#reservationView .elementList {
  padding: 0; }

#reservationView .resourceListBox .elementList li {
  position: relative; }

#reservationView .resourceListBox li > label {
  width: 100%; }

#reservationView .resourceListBox .elementBox {
  border: none;
  margin: 0;
  margin-bottom: 2px;
  padding: 5px 30px;
  min-height: 50px;
  background: #DBE6EC; }
  #reservationView .resourceListBox .elementBox.dead {
    border-left-color: #4f4e4e;
    background-color: #B7B7B7; }

#reservationView .resourceListBox .resource {
  position: relative; }
  #reservationView .resourceListBox .resource:before {
    position: absolute;
    top: 20px;
    left: 7px;
    content: '\F06E';
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    color: #B7B7B7; }
  #reservationView .resourceListBox .resource.clicked:before {
    color: #f55;
    content: '\F06E'; }
  #reservationView .resourceListBox .resource.clicked .fa-map-marker {
    color: #f55; }

#reservationView .resourceListBox .fa-map-marker {
  cursor: pointer;
  top: 25px;
  font-size: 32px;
  color: #4f4e4e; }

#reservationView .resourceListBox .notReady {
  color: #B7B7B7; }

#reservationView .resourceListBox .ready {
  color: #93C47D; }

#reservationView .resourceListBox .fa-big {
  font-size: 22px; }

#reservationView .select-block {
  cursor: pointer;
  color: #4f4e4e;
  position: absolute;
  right: 8px;
  top: 0;
  padding: 12px 0 0 0;
  text-align: center;
  font-size: 12px; }
  #reservationView .select-block i {
    position: relative;
    bottom: inherit;
    right: 0;
    font-size: 15px; }
  #reservationView .select-block .fa-map-marker {
    font-size: 15px !important;
    top: 0 !important; }
  #reservationView .select-block div {
    display: inline;
    float: right; }
  #reservationView .select-block div.block {
    display: block;
    width: 100%; }

#reservationView .wrap-flask {
  cursor: pointer;
  position: absolute;
  top: -20px;
  z-index: 1;
  right: 20px;
  display: inline-block;
  width: 60px;
  height: 0;
  margin: 4px 0;
  padding: 30px 0;
  border-radius: 50px;
  text-align: center;
  background: #20214F;
  color: #fff;
  font-size: 24px;
  line-height: 0px; }
  #reservationView .wrap-flask.empty {
    background: #B7B7B7; }

#reservationView .tabs-info {
  color: #4f4e4e;
  margin: 2px 0 0 0;
  padding-left: 0px;
  height: 15px; }
  #reservationView .tabs-info li {
    font-size: 13px;
    font-weight: 300;
    margin: 2px 12px 2px 0px;
    list-style-type: none; }
    #reservationView .tabs-info li.button {
      cursor: pointer;
      padding: 4px 6px;
      border: 1px solid #4f4e4e; }
      #reservationView .tabs-info li.button.active {
        color: #20214F; }

#reservationView .elementTitle {
  font-size: 15px; }

.filter-type-open #reservationView .wrap-resources {
  height: calc(50vh - 84px); }

.filter-type-open #reservationView .resourceListBox {
  border: none;
  margin-top: 0; }

#reservationView .btn-reserve {
  margin: 10px 20px;
  padding: 0px 25px;
  background: #20214F;
  color: #fff; }
  #reservationView .btn-reserve p {
    margin: 0; }
  #reservationView .btn-reserve.empty {
    background: #B7B7B7; }

#reservationView .btn-classic {
  margin: 10px 20px;
  padding: 0px 25px;
  color: #20214F;
  background-color: #DBE6EC; }
  #reservationView .btn-classic.clear {
    background: #E06666;
    color: #fff; }
  #reservationView .btn-classic p {
    margin: 0; }

#reservationView .loader-true #plotly {
  display: none; }

#reservationView #map {
  width: calc(50vw - 30px);
  height: calc(100vh - 84px);
  display: flex;
  flex-direction: column; }
  #reservationView #map .popin-wrap-elem {
    padding: 10px 0px 0 10px; }
    #reservationView #map .popin-wrap-elem li {
      margin-bottom: 15px;
      list-style-type: none;
      cursor: pointer; }

#reservationView .wrap-icon {
  position: absolute;
  bottom: 9px;
  right: 9px; }

#reservationView .documentation > ul > li {
  display: none; }
  #reservationView .documentation > ul > li.active {
    display: block; }

#reservationView .techno-box {
  border-left: 4px solid #DBE6EC;
  border-right: 4px solid #DBE6EC; }

.list-type-resources {
  margin: 30px 20px; }
  .list-type-resources li {
    display: inline-block;
    list-style-type: none;
    text-align: center;
    margin: 0 10px;
    min-width: 120px; }
    .list-type-resources li:first-child {
      margin-left: 0; }
  .list-type-resources a {
    position: relative;
    border: 1px solid #DBE6EC;
    padding: 10px;
    display: block;
    color: #20214F; }
    .list-type-resources a svg {
      height: 80px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .list-type-resources a rect {
      fill: none;
      stroke: #20214F;
      stroke-width: 2;
      stroke-dasharray: 422, 0; }
    .list-type-resources a:hover, .list-type-resources a:focus {
      text-decoration: none; }
      .list-type-resources a:hover rect, .list-type-resources a:focus rect {
        stroke-dasharray: 0, 310;
        stroke-dashoffset: 48;
        transition: all 1.2s cubic-bezier(0.19, 1, 0.3, 1); }
  .list-type-resources li.active a {
    background: #20214F;
    color: #fff; }
  .list-type-resources li.active i {
    color: #fff; }
  .list-type-resources i {
    font-size: 28px;
    width: auto;
    display: block; }
    .list-type-resources i + span {
      display: block;
      padding: 8px 0 4px 0; }
  .list-type-resources strong {
    font-weight: 100; }
  #reservationView .list-type-resources {
    margin: 10px 0;
    padding: 0; }
    #reservationView .list-type-resources li {
      min-width: 90px; }
    #reservationView .list-type-resources a {
      padding: 5px; }
    #reservationView .list-type-resources i {
      font-size: 22px; }
      #reservationView .list-type-resources i + span {
        padding: 4px 0 2px 0; }
    #reservationView .list-type-resources svg {
      height: 53px; }

@media (min-width: 768px) {
  #dashboardDealer {
    background: linear-gradient(#DBE6EC, #FBFBFB); }
    #dashboardDealer .max-wrap {
      max-width: 90vw; }
    #dashboardDealer .dashboard-card {
      padding: 10px 28px;
      margin: 10px;
      background-color: #fff;
      -moz-box-shadow: 0px 1px 2px 0px #B7B7B7;
      -webkit-box-shadow: 0px 1px 2px 0px #B7B7B7;
      -o-box-shadow: 0px 1px 2px 0px #B7B7B7;
      box-shadow: 0px 1px 2px 0px #B7B7B7;
      filter: progid:DXImageTransform.Microsoft.Shadow(color=$grey-light, Direction=180, Strength=2);
      border-left: 5px solid #20214F; }
      #dashboardDealer .dashboard-card > ul > li {
        margin: 12px 0; }
        #dashboardDealer .dashboard-card > ul > li i.clickable {
          color: #4D718B; }
      #dashboardDealer .dashboard-card .fa-user {
        font-size: 64px;
        border: 1px solid #DBE6EC;
        border-radius: 75px;
        padding: 20px 24px;
        margin: 12px 0;
        color: #DBE6EC; }
      #dashboardDealer .dashboard-card .small-title {
        color: #4f4e4e;
        font-weight: bolder;
        font-size: 14px;
        padding: 4px 0; }
      #dashboardDealer .dashboard-card .big-title {
        color: #20214F;
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 16px;
        padding: 6px; }
      #dashboardDealer .dashboard-card .subtitle {
        text-transform: lowercase;
        font-size: 12px; }
      #dashboardDealer .dashboard-card .fa-big {
        font-size: 22px; }
      #dashboardDealer .dashboard-card.fat i {
        color: #DBE6EC; }
      #dashboardDealer .dashboard-card.fat .big-icon {
        margin: 20px 30px; }
        #dashboardDealer .dashboard-card.fat .big-icon i {
          font-size: 50px; }
      #dashboardDealer .dashboard-card .empty-block-subtitle {
        font-size: 16px; }
      #dashboardDealer .dashboard-card.empty {
        padding: 10px 25px 40px 40px; }
        #dashboardDealer .dashboard-card.empty ul li:first-child {
          padding-top: 50px; }
        #dashboardDealer .dashboard-card.empty .empty-block-subtitle {
          padding: 30px 0 0 0; }
      #dashboardDealer .dashboard-card.welcome {
        padding: 20px 40px 40px 40px; }
        #dashboardDealer .dashboard-card.welcome .top-welcome {
          font-size: 18px;
          color: #20214F; }
          #dashboardDealer .dashboard-card.welcome .top-welcome .title {
            font-size: 18px;
            padding: 10px 0 25px 0;
            font-weight: bold; }
        #dashboardDealer .dashboard-card.welcome p {
          margin: 20px 0; }
        #dashboardDealer .dashboard-card.welcome a {
          padding: 10px;
          background: #20214F;
          color: #fff;
          border-radius: 2px; }
    #dashboardDealer .radio-list .scrollable {
      max-height: 85px;
      overflow-y: scroll;
      overflow-x: hidden; }
    #dashboardDealer .radio-list .line-list {
      border-bottom: 1px solid #DBE6EC;
      padding-bottom: 4px;
      margin: 4px 0; }
      #dashboardDealer .radio-list .line-list:first-child {
        margin-top: 0; }
    #dashboardDealer .radio-list i {
      font-size: 22px;
      color: #20214F; }
    #dashboardDealer .radio-list .picked {
      color: #4D718B;
      font-weight: bold; }
    #dashboardDealer .radio-list .top-details {
      margin-bottom: 6px; }
      #dashboardDealer .radio-list .top-details aside {
        background: #DBE6EC;
        padding: 2px 0 2px 0;
        border-radius: 10px; }
    #dashboardDealer .radio-list .header-list {
      color: #20214F;
      font-weight: bold;
      border-bottom: 1px solid #DBE6EC;
      padding: 2px 0 4px 0;
      text-transform: uppercase;
      margin-bottom: 4px; }
    #dashboardDealer .name {
      border-bottom: 1px solid #B7B7B7;
      padding: 12px 0; }
      #dashboardDealer .name span {
        display: inline-block;
        text-transform: lowercase; }
      #dashboardDealer .name span:first-letter {
        text-transform: uppercase; }
    #dashboardDealer .sub-card .main-icon {
      color: #DBE6EC;
      font-size: 32px; }
    #dashboardDealer .sub-card .sub-icon {
      color: #4D718B; } }

@media (min-width: 0px) {
  .o-documentation {
    height: calc(100vh - 96px); }
    .o-documentation [id^="content"] {
      height: 0px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 0; }
    .o-documentation #tab15:target #content15,
    .o-documentation #tab14:target #content14,
    .o-documentation #tab13:target #content13,
    .o-documentation #tab12:target #content12,
    .o-documentation #tab11:target #content11,
    .o-documentation #tab10:target #content10,
    .o-documentation #tab9:target #content9,
    .o-documentation #tab8:target #content8,
    .o-documentation #tab7:target #content7,
    .o-documentation #tab6:target #content6,
    .o-documentation #tab5:target #content5,
    .o-documentation #tab1:target #content1,
    .o-documentation #tab2:target #content2,
    .o-documentation #tab3:target #content3,
    .o-documentation #tab4:target #content4 {
      height: calc(100vh - 96px); }
    .o-documentation #tab15:target a[href="#tab15"],
    .o-documentation #tab14:target a[href="#tab14"],
    .o-documentation #tab13:target a[href="#tab13"],
    .o-documentation #tab12:target a[href="#tab12"],
    .o-documentation #tab11:target a[href="#tab11"],
    .o-documentation #tab10:target a[href="#tab10"],
    .o-documentation #tab9:target a[href="#tab9"],
    .o-documentation #tab8:target a[href="#tab8"],
    .o-documentation #tab7:target a[href="#tab7"],
    .o-documentation #tab6:target a[href="#tab6"],
    .o-documentation #tab5:target a[href="#tab5"],
    .o-documentation #tab1:target a[href="#tab1"],
    .o-documentation #tab2:target a[href="#tab2"],
    .o-documentation #tab3:target a[href="#tab3"],
    .o-documentation #tab4:target a[href="#tab4"] {
      color: #B7B7B7;
      border-color: #B7B7B7; }
    .o-documentation .tab:target .default {
      height: 0;
      overflow: hidden; }
    .o-documentation .content img {
      max-width: 300px; }
      .o-documentation .content img.large {
        max-width: 600px; }
    .o-documentation.gradient {
      background: -webkit-gradient(linear, left bottom, left top, from(#FBFBFB), to(#DBE6EC)); }
  .testbeds-boxes {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center; }
    .testbeds-boxes li {
      vertical-align: middle;
      display: inline-block;
      *display: inline;
      zoom: 1;
      width: 20%;
      margin-bottom: 20px;
      margin-right: 12px; }
      .testbeds-boxes li a {
        height: 170px;
        background-color: #fff;
        display: block;
        line-height: 150px;
        text-align: center;
        color: #20214F; }
        .testbeds-boxes li a .valign {
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
        .testbeds-boxes li a img {
          margin: 0 auto 15px auto;
          display: block; }
  .o-cloud {
    height: calc(100vh - 96px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .o-cloud.gradient {
      background: -webkit-gradient(linear, left bottom, left top, from(#FBFBFB), to(#DBE6EC)); }
  .cloud-boxes {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center; }
    .cloud-boxes li {
      vertical-align: middle;
      display: inline-block;
      *display: inline;
      zoom: 1;
      width: 45%;
      margin-bottom: 20px;
      margin-right: 12px; }
      .cloud-boxes li div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 450px;
        padding: 20px;
        background-color: #fff;
        line-height: 150px;
        text-align: center;
        color: #20214F; }
        .cloud-boxes li div .valign {
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
        .cloud-boxes li div a:hover {
          text-decoration: none; }
        .cloud-boxes li div .a-howto {
          color: #2f8ead; }
        .cloud-boxes li div .a-disabledlink {
          pointer-events: none; }
        .cloud-boxes li div img {
          margin: 0 auto 15px auto;
          display: block;
          flex: 1; }
        .cloud-boxes li div p {
          flex: initial;
          height: 150px;
          vertical-align: middle; }
        .cloud-boxes li div .o-cloud-logo {
          flex: initial;
          height: 50px;
          vertical-align: middle; }
        .cloud-boxes li div .p-howto {
          flex: 1;
          height: 50px;
          vertical-align: middle;
          font-size: 18px; }
        .cloud-boxes li div h2 {
          height: 125px;
          font-size: 2vw;
          vertical-align: middle; }
        .cloud-boxes li div.gscale {
          -webkit-filter: grayscale(100%);
          background-color: lightgray; }
  .tutorial {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center; }
    .tutorial section > h2 {
      text-align: left; }
    .tutorial img {
      padding: 5px;
      border: 2px solid #DBE6EC; }
    .tutorial li > img {
      margin-bottom: 10px; }
    .tutorial .wrap-box-inline p {
      text-align: left;
      vertical-align: top;
      width: 30%; }
    .tutorial .wrap-box-inline img {
      width: 70%; } }
